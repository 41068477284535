import { Component, OnInit } from '@angular/core';
import { LeagueInfo } from 'src/app/providers/league-info';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomeComponent implements OnInit {

  results: any = {};
  formats: any[] = [];
  genders: any = {};
  lastEvents: any[] = [];
  races: any = {};

  constructor(private dataService: DataService, private leagueInfo: LeagueInfo) { }

  ngOnInit() {
    this.dataService.getPercentByGender(this.leagueInfo.id)
      .subscribe((res) => {
        res.forEach(element => {
          if (element.gender === 'F') {
            this.genders.female = element.percent;
          }
          if (element.gender === 'M') {
            this.genders.male = element.percent;
          }
        });
      });
    this.dataService.getCountByFormat(this.leagueInfo.id)
      .subscribe((res) => {
        /*
        const formats = this.dataService.getFormats();
        res.forEach(element => {
          this.formats.push({ format: formats[element.format - 1].label, count: element.qty });
        });
        */
        this.dataService.getFormats().forEach((item) => {
          let count = 0;
          res.forEach(element => {
            if (Number(element.format) === item.id) {
              count = element.qty;
            }
          });
          this.formats.push({ format: item.label, count: count });
        });
      });
    this.dataService.getCountResults(this.leagueInfo.id).subscribe(res => this.results = res);
    this.dataService.getCountRaces(this.leagueInfo.id).subscribe(res => this.races = res);
    this.dataService.getLastEvents(this.leagueInfo.id, 4).subscribe(res => this.lastEvents = res);
  }

}
