import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-license-history',
  templateUrl: './license-history.component.html',
  styleUrls: ['./license-history.component.less']
})
export class LicenseHistoryComponent implements OnInit, OnChanges {

  license: any = {};
  @Input() licenseId = '';
  @Input() display: boolean;
  @Input() year: string;
  @Output() changeLicenseId = new EventEmitter<string>();

  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this.year = '0';
    this.license = {
      "0": {
        license: {},
        challenge: {},
        results: []
      }
    };
    this.display = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const n = 'licenseId';
    if (changes[n].currentValue !== changes[n].previousValue) {
      if (changes[n].currentValue === '') {
        this.license = {};
        this.display = false;
      } else {
        this.license = {};
        this.license[this.year] = { license: {}, challenge: {}, results: [] };
        this.display = true;
        this.dataService.getLicenseHistory(this.licenseId).subscribe((res) => this.license = res);
      }
    }
  }

  close(): void {
    this.display = false;
    this.changeLicenseId.emit('');
    // this.licenseId = '';
  }

}
