<div>
    <h1>Configuration</h1>
    <div>
        <h2>Distances</h2>
        <div *ngFor="let format of formats">
            <div>-</div>
            <div><input type="text" [(ngModel)]="format.label"></div>
            <div>Supprimer</div>
        </div>
    </div>
</div>