import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Event } from 'src/app/model/event';
import { Message } from 'src/app/model/message';
import { UserInfo } from 'src/app/providers/user-info';
import { DataService } from 'src/app/services/data.service';
import { ToolsService } from 'src/app/services/tools.service';

@Component({
  selector: 'app-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.less']
})
export class EventsListComponent implements OnInit {

  events: Event[];
  connectedUserInfo: UserInfo;
  waiting: boolean;
  message: Message;

  constructor(private dataService: DataService,
              private toolService: ToolsService,
              private userInfo: UserInfo,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.waiting = true;
    this.message = new Message('', '', false);
    let year = new Date().getFullYear();
    const league = this.route.snapshot.paramMap.get('league');
    if (this.route.snapshot.paramMap.get('year') !== null) {
      year = +this.route.snapshot.paramMap.get('year');
    }
    this.connectedUserInfo = this.userInfo;
    this.events = [];
    this.dataService.getEvents(league, year).subscribe((events: Event[]) => {
      this.waiting  = false;
      events.forEach((event: Event) => {
        this.events.push({
          id: event.id,
          label: event.label,
          date: this.toolService.toFrenchDate(this.toolService.fromISODate(event.date)),
          league: event.league,
          saved: true
        });
      });
    }, (err) => {
      this.waiting = false;
      this.message = new Message('Erreur technique', 'Une erreur s\'est produite pendant le chargement des données. Si le problème persiste, veuillez contacter l\'administrateur');
    });
  }


}
