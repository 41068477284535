<div class="back-dialog couche-80" *ngIf="display">
    <div class="dialog dialog-license">
        <div class="title">
            {{license.surname}} {{license.forename}} - {{license[year].license.club}}        
            <div class="button" (click)="close()">X</div>
        </div>
        <div class="content">
            <div class="title">Challenge {{year}}</div>
            <div>Point : {{license[year].challenge.point}}</div>
            <div><span *ngIf="license[year].challenge.rank > 0">Rang : {{license[year].challenge.rank}}</span></div>
        </div>
        <div class="content">
            <div class="title">Résultats aux épreuves</div>
            <div *ngFor="let result of license[year].results">
                <div>
                    <a [routerLink]="['/race-results/' + result.raceId]">{{result.eventLabel}} - {{result.raceLabel}}</a>
                </div>
                <div class="tableau">
                    <div class="c1">{{result.rank}}e</div>
                    <div class="c2">{{result.rankCat}}e {{result.category}}</div>
                    <div class="c3"><span *ngIf="result.point > 0">{{result.point}} pts</span></div>
                </div>
            </div>
        </div>
    </div>
</div>