import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Message } from 'src/app/model/message';
import { LeagueInfo } from 'src/app/providers/league-info';
import { DataService } from 'src/app/services/data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-export-result',
  templateUrl: './export-result.component.html',
  styleUrls: ['./export-result.component.less']
})
export class ExportResultComponent implements OnInit {

  selectYear: string = "";
  years: number[] = [];
  league: string;
  basePath: string = environment.wsBasUrl;
  message: Message ;

  constructor(private dataService: DataService, private route: ActivatedRoute, private leagueInfo: LeagueInfo) { }

  ngOnInit(): void {
    this.message = new Message('', '', false);
    this.league = this.route.snapshot.paramMap.get('league');
    this.dataService.getYears(this.leagueInfo.id).subscribe((years) => {
      years.forEach((item) => {
        this.years.push(item.year);
      });
      this.selectYear = "" + this.years[this.years.length - 1];
    });
  }

}
