<app-popup [message]="message"></app-popup>
<div class="item-list">
    <div class='title'>
        <div class="title-bloc">
            <div class="main">Téléchargement</div>
        </div>
    </div>
    <div class="table">
        <table>
            <tr>
                <td colspan="2">
                    <select [(ngModel)]="selectYear">
                        <option *ngFor="let year of years" [value]="year">{{year}}</option>
                    </select>
                </td>
            </tr>
            <tr>
                <th style="padding: 5px;">Fichier</th>
                <th style="padding: 5px;">Lien</th>
            </tr>
            <tr>
                <td>challenge mini-poussine femme</td>
                <td><a href="{{basePath}}/race-result/v1/export/leagues/hdf/challenge/{{selectYear}}/gender/F/category/MP/resultat_challenge_MP_F_{{selectYear}}">Télécharger</a></td>
            </tr>
            <tr>
                <td>challenge poussine femme</td>
                <td><a href="{{basePath}}/race-result/v1/export/leagues/hdf/challenge/{{selectYear}}/gender/F/category/PO/resultat_challenge_PO_F_{{selectYear}}">Télécharger</a></td>
            </tr>
            <tr>
                <td>challenge pupille femme</td>
                <td><a href="{{basePath}}/race-result/v1/export/leagues/hdf/challenge/{{selectYear}}/gender/F/category/PU/resultat_challenge_PU_F_{{selectYear}}">Télécharger</a></td>
            </tr>
            <tr>
                <td>challenge benjmaine femme</td>
                <td><a href="{{basePath}}/race-result/v1/export/leagues/hdf/challenge/{{selectYear}}/gender/F/category/BE/resultat_challenge_BE_F_{{selectYear}}">Télécharger</a></td>
            </tr>
            <tr>
                <td>challenge minime femme</td>
                <td><a href="{{basePath}}/race-result/v1/export/leagues/hdf/challenge/{{selectYear}}/gender/F/category/MI/resultat_challenge_MI_F_{{selectYear}}">Télécharger</a></td>
            </tr>
            <tr>
                <td>challenge cadette femme</td>
                <td><a href="{{basePath}}/race-result/v1/export/leagues/hdf/challenge/{{selectYear}}/gender/F/category/CA/resultat_challenge_CA_F_{{selectYear}}">Télécharger</a></td>
            </tr>
            <tr>
                <td>challenge junior femme</td>
                <td><a href="{{basePath}}/race-result/v1/export/leagues/hdf/challenge/{{selectYear}}/gender/F/category/JU/resultat_challenge_JU_F_{{selectYear}}">Télécharger</a></td>
            </tr>
            <tr>
                <td>challenge sénior femme</td>
                <td><a href="{{basePath}}/race-result/v1/export/leagues/hdf/challenge/{{selectYear}}/gender/F/category/SE/resultat_challenge_SE_F_{{selectYear}}">Télécharger</a></td>
            </tr>
            <tr>
                <td>challenge vétérane femme</td>
                <td><a href="{{basePath}}/race-result/v1/export/leagues/hdf/challenge/{{selectYear}}/gender/F/category/VE/resultat_challenge_VE_F_{{selectYear}}">Télécharger</a></td>
            </tr>
            <tr>
                <td>challenge mini-poussin homme</td>
                <td><a href="{{basePath}}/race-result/v1/export/leagues/hdf/challenge/{{selectYear}}/gender/M/category/MP/resultat_challenge_MP_M_{{selectYear}}">Télécharger</a></td>
            </tr>
            <tr>
                <td>challenge poussin homme</td>
                <td><a href="{{basePath}}/race-result/v1/export/leagues/hdf/challenge/{{selectYear}}/gender/M/category/PO/resultat_challenge_PO_M_{{selectYear}}">Télécharger</a></td>
            </tr>
            <tr>
                <td>challenge pupille homme</td>
                <td><a href="{{basePath}}/race-result/v1/export/leagues/hdf/challenge/{{selectYear}}/gender/M/category/PU/resultat_challenge_PU_M_{{selectYear}}">Télécharger</a></td>
            </tr>
            <tr>
                <td>challenge benjmain homme</td>
                <td><a href="{{basePath}}/race-result/v1/export/leagues/hdf/challenge/{{selectYear}}/gender/M/category/BE/resultat_challenge_BE_M_{{selectYear}}">Télécharger</a></td>
            </tr>
            <tr>
                <td>challenge minime homme</td>
                <td><a href="{{basePath}}/race-result/v1/export/leagues/hdf/challenge/{{selectYear}}/gender/M/category/MI/resultat_challenge_MI_M_{{selectYear}}">Télécharger</a></td>
            </tr>
            <tr>
                <td>challenge cadet homme</td>
                <td><a href="{{basePath}}/race-result/v1/export/leagues/hdf/challenge/{{selectYear}}/gender/M/category/CA/resultat_challenge_CA_M_{{selectYear}}">Télécharger</a></td>
            </tr>
            <tr>
                <td>challenge junior homme</td>
                <td><a href="{{basePath}}/race-result/v1/export/leagues/hdf/challenge/{{selectYear}}/gender/M/category/JU/resultat_challenge_JU_M_{{selectYear}}">Télécharger</a></td>
            </tr>
            <tr>
                <td>challenge sénior homme</td>
                <td><a href="{{basePath}}/race-result/v1/export/leagues/hdf/challenge/{{selectYear}}/gender/M/category/SE/resultat_challenge_SE_M_{{selectYear}}">Télécharger</a></td>
            </tr>
            <tr>
                <td>challenge vétéran homme</td>
                <td><a href="{{basePath}}/race-result/v1/export/leagues/hdf/challenge/{{selectYear}}/gender/M/category/VE/resultat_challenge_VE_M_{{selectYear}}">Télécharger</a></td>
            </tr>
            <tr>
                <td>challenge adultes Clubs</td>
                <td><a href="{{basePath}}/race-result/v1/export/leagues/hdf/challenge/{{selectYear}}/adults/challenge_adultes_clubs">Télécharger</a></td>
            </tr>
            <tr>
                <td>challenge jeunes Clubs</td>
                <td><a href="{{basePath}}/race-result/v1/export/leagues/hdf/challenge/{{selectYear}}/youngs/challenge_jeunes_clubs">Télécharger</a></td>
            </tr>
        </table>
        <div>
        </div>
    </div>
</div>