import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Event } from 'src/app/model/event';
import { Format } from 'src/app/model/format';
import { Race } from 'src/app/model/race';
import { UserInfo } from 'src/app/providers/user-info';
import { DataService } from 'src/app/services/data.service';
import { ToolsService } from 'src/app/services/tools.service';

@Component({
  selector: 'app-race-results',
  templateUrl: './race-results.component.html',
  styleUrls: ['./race-results.component.less']
})
export class RaceResultsComponent implements OnInit {

  results: any[];
  race: Race;
  event: Event;
  raceFormat: Format = new Format();

  constructor(private dataService: DataService,
              private route: ActivatedRoute,
              private userInfo: UserInfo,
              private tool: ToolsService) { }

  ngOnInit() {
    this.results = [];
    this.race = new Race(0, '', []);
    this.event = new Event();
    const raceId = +this.route.snapshot.paramMap.get('id');
    const formats = this.dataService.getFormats();
    this.dataService.getEventOfRace(raceId).subscribe((event: Event) => {
      event.date = this.tool.toFrenchDate(this.tool.fromISODate(event.date));
      this.event = event;
    });
    this.dataService.getRaceResults(raceId).subscribe((results) => this.results = results);
    this.dataService.getRace(raceId).subscribe((race: Race) => {
      this.race = race;
      formats.forEach((item) => {
        if (race.format === item.id) {
          this.raceFormat = item;
        }
      });
    });
  }

}
