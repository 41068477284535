import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-waiting',
  templateUrl: './waiting.component.html',
  styleUrls: ['./waiting.component.less']
})
export class WaitingComponent implements OnInit {

  @Input() waiting: boolean;
  @Input() message: string;
  constructor() { }

  ngOnInit(): void {
  }

}
