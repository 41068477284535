<div class="challenge-list">
    <div class="title">
        <div class="title-bloc">
            <div class="main">{{title}}</div>
        </div>
    </div>
    <div class="boxes-list">
        <div *ngFor="let categorie of categories" class="line">
            <div class="box">
                <div class="gender-f">
                    <img src="img/gender_symbols.png">
                </div>
                <div class="categorie categorie-f">
                    {{categorie.id}}
                </div>
                <div class="link">
                    <a [routerLink]="['/challenge/' + league + '/F/' + categorie.id + '/' + year ]" routerLinkActive="router-link-active">
                        {{categorie.label}}<br/>Féminine
                    </a>
                </div>
                <div style="clear: both"></div>
            </div>
            <div class="box">
                <div class="gender-h">
                    <img src="img/gender_symbols.png">
                </div>
                <div class="categorie categorie-h">
                    {{categorie.id}}
                </div>
                <div class="link">
                    <a [routerLink]="['/challenge/' + league + '/M/' + categorie.id + '/' + year ]" routerLinkActive="router-link-active">
                        {{categorie.label}}<br/>Masculin
                    </a>
                </div>
                <div style="clear: both"></div>
            </div>
        </div>
    </div>
</div>
