import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-load-token',
  templateUrl: './load-token.component.html',
  styleUrls: ['./load-token.component.less']
})
export class LoadTokenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
