<div class="status-bar">
    <div class="vertical-menu">
        <div class="icon" (click)="displayMenu()">=</div>
        <div class="block-menu" *ngIf="verticalMenuShown">
            <div class="item pointer" [routerLink]="['/accueil']" routerLinkActive="router-link-active">ACCUEIL</div>
            <div class="item pointer" [routerLink]="['/challenge/' + lLeagueInfo.id + '/young']" routerLinkActive="router-link-active">CHALLENGE JEUNES</div>
            <div class="item pointer" [routerLink]="['/challenge/' + lLeagueInfo.id + '/adult']" routerLinkActive="router-link-active">CHALLENGE ADULTES</div>
            <div class="item pointer" [routerLink]="['challenge-club' + lLeagueInfo.id]" routerLinkActive="router-link-active">CHALLENGE CLUB</div>
            <div class="item pointer" [routerLink]="['/events/' + lLeagueInfo.id]" routerLinkActive="router-link-active">RESULTATS</div>
            <div class="item pointer" [routerLink]="['/archive/' + lLeagueInfo.id]" routerLinkActive="router-link-active">ARCHIVES</div>
        </div>
    </div>
    <div class="account">
        <div class="icone-block"><!--<img src="img/user-icon.png" class="icone">--></div>
        <div class="button-block" *ngIf="!userInfo.authentified">
            <button [routerLink]="['/login']" routerLinkActive="router-link-active" class="button">Se connecter</button>
        </div>
        <div class="user-block" *ngIf="userInfo.authentified">
            {{userInfo.user.surname}} {{userInfo.user.forename}}
        </div>
        <div style="clear: both;"></div>
    </div>
    <div style="clear: both;"></div>
</div>
<div class="header">
    <div class="background">
        <img src="https://triathlonhdf.fr/wp-content/uploads/2018/10/cropped-T.GROMIK-3003.jpg" class="image">
    </div>
    <div class='title1'>
        LIGUE HAUTS-DE-FRANCE DE TRIATHLON
    </div>
    <div class='title2'>
        et des disciplines enchaînées
    </div>
    <div class='menu'>
        <div class="block-menu">
            <div class="item left"></div>
            <div class="item pointer i1" [routerLink]="['/accueil']" routerLinkActive="router-link-active">ACCUEIL</div>
            <div class="item pointer i2" [routerLink]="['/challenge/' + lLeagueInfo.id + '/young']" routerLinkActive="router-link-active">CHALLENGE JEUNES</div>
            <div class="item pointer i3" [routerLink]="['/challenge/' + lLeagueInfo.id + '/adult']" routerLinkActive="router-link-active">CHALLENGE ADULTES</div>
            <div class="item pointer i4" [routerLink]="['challenge-club/' + lLeagueInfo.id]" routerLinkActive="router-link-active">CHALLENGE CLUB</div>
            <div class="item pointer i5" [routerLink]="['/events/' + lLeagueInfo.id]" routerLinkActive="router-link-active">RESULTATS</div>
            <div class="item pointer i6" [routerLink]="['/archive/' + lLeagueInfo.id]" routerLinkActive="router-link-active">ARCHIVES</div>
            <div class="item right"></div>
        </div>
    </div>
</div>

<div class="main">
    <div class="admin-menu" *ngIf="userInfo.authentified">
        <nav>
            <ul>
                <li>
                    <a [routerLink]="['/dashboard']" routerLinkActive="router-link-active">Tableau de bord</a>
                </li>
                <li>
                    <a [routerLink]="['/import-file']" routerLinkActive="router-link-active">Importer des résultats</a>
                </li>
                <li>
                    <a [routerLink]="['/export-file']" routerLinkActive="router-link-active">Exporter des résultats</a>
                </li>
                <li>
                    <a [routerLink]="['/import-licenses']" routerLinkActive="router-link-active">Importer les licenciés</a>
                </li>
                <li>
                    <a [routerLink]="['/events/' + lLeagueInfo.id]" routerLinkActive="router-link-active">Liste des évènements</a>
                </li>
                <li>
                    <a [routerLink]="['/configuration']" routerLinkActive="router-link-active">configuration</a>
                </li>
            </ul>
        </nav>
    </div>
    <div class="content">
        <router-outlet></router-outlet>
    </div>
</div>
