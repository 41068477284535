import { Message } from '../../model/message';
import { Component, Input, OnInit, Output } from '@angular/core';
import * as EventEmitter from 'events';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.less']
})
export class PopupComponent implements OnInit {

  @Input() message: Message;
  @Input() type: string;
  @Output() confirm: EventEmitter = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    if (this.type === 'confirm') {

    }
  }

  hide(): void {
    this.message.display = false;
  }
  cancel(): void {
    this.confirm.emit('cancel');
  }

}
