import { Categorie } from './categorie';

export class Race {
    public id: number;
    public label: string;
    public format: number;
    public categoriesForPoint: Categorie[];
    public categories: string[];
    public inaffectedCategories: any[];
    public genders: any[];
    public uniqueGender: boolean;
    public gender: string;
    public category: string;
    public uniqueCategory: boolean;
    public maleLabel: string;
    public femaleLabel: string;
    public eventId: number;
    public saved: boolean;
    public resultsSaved: boolean;

    constructor(id: number, label: string, categories: Categorie[]) {
      this.id = id;
      this.label = label;
      this.format = 0;
      this.categoriesForPoint = categories;
      this.categories = [];
      this.inaffectedCategories = [];
      this.genders = [ { code: '', label: ' -- select -- ' } ];
      this.gender = '';
      this.uniqueGender = false;
      this.maleLabel = '';
      this.femaleLabel = '';
      this.category = '';
      this.uniqueCategory = false;
      this.eventId = 0;
      this.saved = false;
      this.resultsSaved = false;
    }
}
