<app-waiting [waiting]="waiting" [message]="''"></app-waiting>
<app-popup [message]="message"></app-popup>
<app-license-history [display]="displayLicense" [licenseId]="licenseIdToDisplay" [year]="yearOfLicenseHistory" (changeLicenseId)="changeLicenseId($event)"></app-license-history>
<div class="challenge-result">
    <div class="title">
        <div class="title-bloc">
            <div class="main">Challenge {{year}} {{genderLabel}} {{catLabel}}</div>
        </div>
    </div>
    <div class="tableau">
        <div class="entete">
            <div class="c1">Cl.</div>
            <div class="c2">Pts</div>
            <div class="c3">
                Nom
            </div>
            <div class="c4">
                Club
            </div>
            <!--<div style="flex: 0 0 50px; overflow: hidden;">
                Cat.
            </div>-->
            <!--
            <div style="flex: 1 1 100px; width: 100%; overflow: auto; display: flex;">
                <div style="flex: 0 0 30px; overflow: hidden; writing-mode: vertical-rl; text-orientation: mixed; text-align: center;" *ngFor="let event of challenge.events">
                    {{event.label}}
                </div>
            </div>-->
            <div class="c5">
                <div style="display: flex;">
                    <div style="flex: 0 0 15px;"><img src="img/previous.png" class="prev" (click)="prevEvent()" *ngIf="currentEventIndex != -1"></div>
                    <!--<span (click)="prevEvent()" *ngIf="currentEventIndex != -1" style="cursor: pointer; color: red; font-weight: bold;">&lt;</span>&nbsp;-->
                    <div style="flex: 1 1 auto">{{event.label}}</div>
                    <!--&nbsp;<span (click)="nextEvent()" *ngIf="currentEventIndex != -1" style="cursor: pointer; color: red; font-weight: bold;">&gt;</span>-->
                    <div style="flex: 0 0 15px;"><img src="img/next.png" class="next" (click)="nextEvent()" *ngIf="currentEventIndex != -1"></div>
                </div>
            </div>

        </div>
        <div class="ligne" *ngFor="let ranking of challenge.challengeRank; index as rank">
            <div class="c1">
                <!--{{ranking.rank}}-->
                {{rank + 1}}
            </div>
            <div class="c2">
                {{ranking.point}} <span class="pointLabel">pts</span>
            </div>
            <div class="c3">
                <span (click)="displayLicenseHistory(ranking.license, ranking.year)" style="cursor: pointer;">{{ranking.surname}} {{ranking.forename}}</span>
            </div>
            <div class="c4">
                {{ranking.club}}
            </div>
            <!--<div style="flex: 0 0 50px; overflow: hidden;">
                {{ranking.category}}
            </div>-->
            <!--<div style="flex: 1 1 100px; width: 100%; overflow: auto; display: flex;">
                <div style="flex: 0 0 30px; overflow: hidden; text-align: center;" *ngFor="let event of challenge.events">
                    {{ranking.results.event[event.id].point}}
                </div>
            </div>-->
            <div class="c5">
                    {{ranking.results.event[event.id].point}}
            </div>
        </div>
    </div>
</div>