import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Categorie } from 'src/app/model/categorie';
import { UserInfo } from 'src/app/providers/user-info';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-select-challenge',
  templateUrl: './select-challenge.component.html',
  styleUrls: ['./select-challenge.component.less']
})
export class SelectChallengeComponent implements OnInit {

  title: string;
  categories: Categorie[];
  year: number;
  league: string;
  constructor(private dataService: DataService,
              private route: ActivatedRoute,
              private router: Router,
              private userInfo: UserInfo) { }

  ngOnInit(): void {
    this.categories = [];

    this.league = this.route.snapshot.paramMap.get('league');
    const type = this.route.snapshot.paramMap.get('type');
    if (this.route.snapshot.paramMap.get('year') !== null) {
      this.year = +this.route.snapshot.paramMap.get('year');
    } else {
      this.year = new Date().getFullYear();
    }
    if (type === 'young') {
      this.categories = this.dataService.getCategories().filter((cat) => cat.young === true && cat.challenge == true);
      this.title = 'Challenge Jeunes';
    } else {
      this.categories = this.dataService.getCategories().filter((cat) => cat.young === false && cat.challenge == true);
      this.title = 'Challenge Adultes';
    }

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const ageType = this.route.snapshot.paramMap.get('type');
        if (ageType === 'young') {
          this.categories = this.dataService.getCategories().filter((cat) => cat.young === true && cat.challenge == true);
          this.title = 'Challenge Jeunes';
        } else {
          this.categories = this.dataService.getCategories().filter((cat) => cat.young === false && cat.challenge == true);
          this.title = 'Challenge Adultes';
        }
      }
    });
  }

}
