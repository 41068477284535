import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Event } from 'src/app/model/event';
import { Message } from 'src/app/model/message';
import { Race } from 'src/app/model/race';
import { UserInfo } from 'src/app/providers/user-info';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-event-results',
  templateUrl: './event-results.component.html',
  styleUrls: ['./event-results.component.less']
})
export class EventResultsComponent implements OnInit {

  event: Event;
  races: Race[];
  raceResults: any[];
  loadRace: boolean;
  selectedRace: number;
  message: Message;
  waiting: boolean;

  constructor(private dataService: DataService,
              private activatedRoute: ActivatedRoute,
              private userInfo: UserInfo) { }

  ngOnInit(): void {
    this.waiting = true;
    let waitingEvent = true;
    let waitingRaces = true;
    this.races = [];
    this.event = new Event();
    this.message = new Message('', '', false);
    const eventId = +this.activatedRoute.snapshot.paramMap.get('id');
    this.dataService.getEvent(eventId).subscribe((event: Event) => {
      this.event = event;
      waitingEvent = false;
      if (!waitingEvent && !waitingRaces) {
        this.waiting = false;
      }
    }, (err) => {
      waitingEvent = false;
      if (!waitingEvent && !waitingRaces) {
        this.waiting = false;
      }
      this.message = new Message('Erreur', 'Une erreur s\'est produite lors de la recherche des résultats.');
    });
    this.dataService.getRaces(eventId).subscribe((races: Race[]) => {
      this.races = [ new Race(0, '-- Choisir une épreuve --', [])].concat(races);
      this.selectedRace = 0;
      waitingRaces = false;
      if (!waitingEvent && !waitingRaces) {
        this.waiting = false;
      }
    }, (err) => {
      this.message = new Message('Erreur', 'Une erreur s\'est produite lors de la recherche des résultats.');
      waitingRaces = false;
      if (!waitingEvent && !waitingRaces) {
        this.waiting = false;
      }
    });
  }

  changeRace(): void{
    this.waiting = true;
    this.loadRace = true;
    this.dataService.getRaceResults(this.selectedRace).subscribe((results) => {
      this.raceResults = results;
      this.loadRace = false;
      this.waiting = false;
    }, (err) => {
      this.loadRace = false;
      this.waiting = false;
      this.message = new Message('Erreur', 'Une erreur s\'est produite lors de la recherche des résultats.');
    });
  }
}
