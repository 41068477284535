<div class="home">
    <div class="last-races">
        <div class="picture">
            <img src="https://www.fftri.com/wp-content/uploads/2020/08/CHPT-FRANCE-CLUBS-D2-HOMMES-CHATEAUROUX-20200822-21-wpcf_761x465.jpg">
        </div>
        <div class="title">Dernières épreuves ajoutées</div>
        <div class="races">
            <div class="race" *ngFor="let event of lastEvents">
                <img src="img/fleche_puce.png" style="height: 15px;">&nbsp;
                <a [href]="'races-of-event/' + event.id">{{event.label}}</a>
            </div>
        </div>
    </div>
    <div class="stats narrow-stat">
        <div class="stat-list">
            <div class="stat">
                <div class="stat-box">
                    <div class="stat-content">
                        {{races.qty}}
                    </div>
                    <div class="stat-title">Epreuves</div>
                </div>
            </div>
            <div class="stat">
                <div class="stat-box">
                    <div class="stat-content">
                        {{results.qty}}
                    </div>
                    <div class="stat-title">Participants</div>
                </div>
            </div>
        </div>
    </div>
    <div class="stats narrow-stat">
        <div class="stat-list">
            <div class="stat">
                <div class="stat-box">
                    <div class="stat-content">{{genders.male}}%</div>
                    <div class="stat-title">Homme</div>
                </div>
            </div>
            <div class="stat">                
                <div class="stat-box">
                    <div class="stat-content">{{genders.female}}%</div>
                    <div class="stat-title">Femme</div>
                </div>
            </div>
        </div>
    </div>
    <!--
    <div class="stats large-stat">
        <div class="stat-enum">
            <div class="stat" *ngFor="let format of formats">
                <div class="stat-box">
                    <div class="stat-content">{{format.count}}</div>
                    <div class="stat-title">Format {{format.format}}</div>
                </div>
            </div>
            <div style='clear: both'></div>
        </div>
    </div>
    --> 
</div>
