<app-waiting [waiting]="waiting" [message]="''"></app-waiting>
<app-popup [message]="message"></app-popup>
<div class="item-list">
    <div class='title'>
        <div class="title-bloc">
            <div class="main">Evènements</div>
        </div>
    </div>
    <div class="table">
        <table>
            <tr>
                <th style="padding: 5px;">Date</th>
                <th style="padding: 5px;">Nom</th>
                <th *ngIf="connectedUserInfo.authentified"></th>
                <th *ngIf="connectedUserInfo.authentified"></th>
            </tr>
            <tr *ngFor="let event of events">
                <td>{{event.date}}</td>
                <td><a href [routerLink]="['/races-of-event/' + event.id ]" routerLinkActive="router-link-active">{{event.label}}</a></td>
                <td *ngIf="connectedUserInfo.authentified"><a href [routerLink]="['/event-results/' + event.id ]" routerLinkActive="router-link-active">Résultats</a></td>
                <td *ngIf="connectedUserInfo.authentified"><a href [routerLink]="['/import-file/event/' + event.id ]" routerLinkActive="router-link-active">Ajouter des résultats</a></td>
            </tr>
        </table>
        <div>
        </div>
    </div>
</div>