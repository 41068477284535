import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { ImportResultComponent } from './pages/import-result/import-result.component';
import { RaceResultsComponent } from './pages/race-results/race-results.component';
import { ConfigurationComponent } from './pages/configuration/configuration.component';
import { RacesListComponent } from './pages/races-list/races-list.component';
import { LoginComponent } from './pages/login/login.component';
import { EventsListComponent } from './pages/events-list/events-list.component';
import { ImportLicensesComponent } from './pages/import-licenses/import-licenses.component';
import { UserInfo } from './providers/user-info';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { EventResultsComponent } from './pages/event-results/event-results.component';
import { PopupComponent } from './component/popup/popup.component';
import { ChallengeResultsComponent } from './pages/challenge-results/challenge-results.component';
import { SelectChallengeComponent } from './pages/select-challenge/select-challenge.component';
import { WaitingComponent } from './component/waiting/waiting.component';
import { LoadTokenComponent } from './pages/load-token/load-token.component';
import { ArchiveComponent } from './pages/archive/archive.component';
import { LicenseHistoryComponent } from './component/license-history/license-history.component';
import { LeagueInfo } from './providers/league-info';
import { ExportResultComponent } from './pages/export-result/export-result.component';
import { ClubChallengeResultsComponent } from './pages/club-challenge-results/club-challenge-results.component';
import { SelectChallengeClubComponent } from './pages/select-challenge-club/select-challenge-club.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ImportResultComponent,
    RaceResultsComponent,
    ConfigurationComponent,
    RacesListComponent,
    LoginComponent,
    EventsListComponent,
    ImportLicensesComponent,
    DashboardComponent,
    EventResultsComponent,
    PopupComponent,
    ChallengeResultsComponent,
    SelectChallengeComponent,
    WaitingComponent,
    LoadTokenComponent,
    ArchiveComponent,
    LicenseHistoryComponent,
    ExportResultComponent,
    ClubChallengeResultsComponent,
    SelectChallengeClubComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [
    UserInfo,
    LeagueInfo
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
