<div class="challenge-list">
    <div class="title">
        <div class="title-bloc">
            <div class="main">{{title}}</div>
        </div>
    </div>
    <div class="boxes-list">
        <div class="line">
            <div class="box">
                <div class="gender-f">
                    
                </div>
                <div class="categorie categorie-f">
                    J
                </div>
                <div class="link">
                    <a [routerLink]="[ '/challenge-club/' + league + '/' + year + '/youngs' ]" routerLinkActive="router-link-active">
                        Challenge club<br />Jeunes
                    </a>
                </div>
                <div style="clear: both"></div>
            </div>
            <div class="box">
                <div class="gender-h">
                    
                </div>
                <div class="categorie categorie-h">
                    A
                </div>
                <div class="link">
                    <a [routerLink]="[ '/challenge-club/' + league + '/' + year + '/adults' ]" routerLinkActive="router-link-active">
                        Challenge club<br />Adultes
                    </a>
                </div>
                <div style="clear: both"></div>
            </div>
        </div>
    </div>
</div>
