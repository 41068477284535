<!--<div style="position: fixed; z-index: -1; top: 0px; left: 0px; width: 100%;">
    <img src="img/japprendsarouler.jpg" style="width: 100%;">
</div>-->
<div style="display: flex; overflow: hidden; height: 300px; padding: 30px 0px 0px 0px; box-sizing: border-box;">

    <div style="flex: 1 1 100px;"></div>
    <div style="font-size: 1.2em; flex: 0 1 200px; background-color:  rgb(178, 210, 232); padding: 20px;">
        <div>
            <span style="color: rgb(67, 90, 106); font-weight: bold;">Connexion</span>
        </div>
        <div>
            <span><input type="text" (keyup.enter)="authenticate()" [(ngModel)]="user" placeholder="Saisissez votre login" style="padding: 10px; border-radius: 3px; margin-top: 10px; width: 100%; box-sizing: border-box;"></span>
        </div>
        <div>
            <span><input type="password" (keyup.enter)="authenticate()" [(ngModel)]="password" placeholder="Saisissez votre mot de passe" style="padding: 10px; margin-top: 10px; border-radius: 3px; width: 100%; box-sizing: border-box"></span>
        </div>
        <div>
            <input type="button" (click)="authenticate()" value="Se connecter" style="width: 100%; font-weight: bold; margin-top: 10px; padding: 8px 5px; box-sizing: border-box; border: 1px solid rgb(100, 130, 150); background-color: rgb(178, 210, 232); cursor: pointer; color: rgb(67, 90, 106);">
        </div>
        <div style="color: rgb(182, 4, 4); font-size: 0.8em;">
            {{message}}
        </div>
    </div>
    <div style="flex: 1 1 100px;"></div>
</div>