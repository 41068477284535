import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpClient: HttpClient) { }

  public authenticate(user: string, password: string): Observable<any> {
    return this.httpClient.post(`${environment.wsBasUrl}/login/v1/authenticate`, { user: user, password: password });
  }
  public checkToken(token: string): Observable<any> {
    return this.httpClient.post(`${environment.wsBasUrl}/login/v1/token`, { token: `${token}` });
  }
}
