import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserInfo } from 'src/app/providers/user-info';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.less']
})
export class ConfigurationComponent implements OnInit {

  formats: any[] = [];
  constructor(private router: Router, private userInfo: UserInfo) { }

  ngOnInit() {
  }

}
