<div class="races-list">
    <div class="title">
        <div class="title-bloc">
            <div class="main">{{event.label}}</div>
            <div class="sub">{{event.date}}</div>
        </div>
    </div>
    <div class="table">
        <table>
            <tr>
                <th style="padding: 5px;">Nom</th>
                <th style="padding: 5px;">Sexe</th>
                <th style="padding: 5px;">Catégories comptabilisant des points</th>
                <th style="padding: 5px;">Format</th>
                <th *ngIf="connectedUserInfo.authentified"></th>
            </tr>
            <tr *ngFor="let race of races">
                <td style="padding: 5px;"><a href [routerLink]="['/race-results/' + race.id ]" routerLinkActive="router-link-active">{{race.label}}</a></td>
                <td style="padding: 5px;">{{race.gender}}</td>
                <td style="padding: 5px;">{{race.cats}}</td>
                <td style="padding: 5px; text-align: center;"><a href [routerLink]="['/race-results/' + race.id ]" routerLinkActive="router-link-active">{{race.labelFormat}}</a></td>
                <td style="padding: 5px;" *ngIf="connectedUserInfo.authentified"><input type="button" value="Supprimer" (click)="deleteRace(race)" class="deleteButton"></td>
            </tr>
        </table>
    </div>
</div>