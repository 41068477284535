import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToolsService {

  constructor() { }

  public fromFrenchDate(date: string): Date {
    /([0-9]+)\/([0-9]+)\/([0-9]+)/.exec(date);
    let _date = new Date(Number(RegExp.$3), Number(RegExp.$2) - 1, Number(RegExp.$1));
    return _date;
  }

  public fromISODate(date: string): Date {
    // /([0-9]+)-([0-9]+)-([0-9]+)/.exec(date);
    // let _date = new Date(Number(RegExp.$1), Number(RegExp.$2), Number(RegExp.$3));
    // return _date;
    return new Date(date);
  }

  public toFrenchDate(date: Date): string {
    return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
  }

  public toISODateOnly(date: Date): string {
    return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
  }


}
