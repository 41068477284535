<app-popup [message]="message"></app-popup>
<app-waiting [waiting]="waiting" [message]="''"></app-waiting>
<h1>{{event.label}}</h1>
<div>
    Epreuves : 
    <select [(ngModel)]="selectedRace" (change)="changeRace()" [disabled]="loadRace">
        <option *ngFor="let race of races" [value]="race.id">{{race.label}}</option>
    </select>
</div>
<div>
    <table>
        <tr>
            <th>Cl.</th>
            <th>Nom</th>
            <th>Prénom</th>
            <th>Sexe</th>
            <th>Cat.</th>
            <th>Club</th>
        </tr>
        <tr *ngFor="let result of raceResults">
            <td>{{result.rank}}</td>
            <td>{{result.surname}}</td>
            <td>{{result.forename}}</td>
            <td>{{result.gender}}</td>
            <td>{{result.category}}</td>
            <td>{{result.club}}</td>
        </tr>
    </table>
</div>