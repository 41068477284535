import { Categorie } from './categorie';
import { Column } from './column';
import { Race } from './race';
import { Type } from './type';
 
export class Sheet {
    public id: string;
    public columns: Column[];
    public multipleRace: boolean;
    public uniqueCategory: boolean;
    public types: Type[];
    public visibility: boolean;
    public categories: Categorie[];
    public results: any[];
    public rawResults: any[];
    public races: Race[];
    public licensesFound: number;
    public iteration: number;
    public saved: boolean;
    public calculated: boolean;

    public constructor() {
        this.columns = [];
        this.types = [];
        this.categories = [];
        this.results = [];
        this.rawResults = [];
        this.races = [];
        this.licensesFound = 0;
        this.saved = false;
        this.calculated = false;
    }
}
