import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { LeagueInfo } from './providers/league-info';
import { UserInfo } from './providers/user-info';
import { UserService } from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit  {

  title = 'challenge';
  initApp: boolean;
  token: string;
  adminPage = [ '/import-file', '/importe-licenses', '/configuration', '/dashboard' ];
  lLeagueInfo: LeagueInfo;

  verticalMenuShown: boolean = false;

  constructor(public userInfo: UserInfo,
              private userService: UserService,
              private router: Router,
              private leagueInfo: LeagueInfo) {
  }

  ngOnInit() {
    this.initApp = true;
    this.token = '';
    this.leagueInfo.id = 'hdf';
    this.lLeagueInfo = this.leagueInfo;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.verticalMenuShown = false;
      }
      if (event instanceof NavigationStart && this.token === '' && this.initApp) {
        this.token = localStorage.getItem('token');
        if (this.token !== null) {
          const page = event.url;
          if (this.adminPage.indexOf(page) > -1) {
            this.router.navigateByUrl('/load-token');
          }
          this.userService.checkToken(localStorage.getItem('token')).subscribe((user) => {
            this.userInfo.user = user;
            this.userInfo.authentified = true;
            this.initApp = false;
            if (this.adminPage.indexOf(page) > -1) {
              this.router.navigateByUrl(page);
            }
          }, (err) => {
            this.initApp = false;
            if (this.adminPage.indexOf(page) > -1) {
              this.router.navigateByUrl('/login');
            }
          });
        } else {
          this.initApp = false;
        }
      }
      if (event instanceof NavigationEnd) {
      }
    });
  }

  displayMenu(): void {
    this.verticalMenuShown = !this.verticalMenuShown;
  }
}
