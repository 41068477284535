import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UserInfo } from 'src/app/providers/user-info';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-select-challenge-club',
  templateUrl: './select-challenge-club.component.html',
  styleUrls: ['./select-challenge-club.component.less']
})
export class SelectChallengeClubComponent implements OnInit {
  title: string;
  year: number;
  league: string;
  type: string;
  constructor(private dataService: DataService,
              private route: ActivatedRoute,
              private router: Router,
              private userInfo: UserInfo) { }

  ngOnInit(): void {
    this.league = this.route.snapshot.paramMap.get('league');
    if (this.route.snapshot.paramMap.get('year') !== null) {
      this.year = +this.route.snapshot.paramMap.get('year');
    } else {
      this.year = new Date().getFullYear();
    }
    this.title = 'Challenge Club';

    
  }

}
