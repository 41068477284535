<app-waiting [waiting]="waiting" [message]="''"></app-waiting>
<app-popup [message]="message"></app-popup>
<div class="form" style="padding: 10px; ">
    <h1>Importer les licenciés</h1>
    <div>
        <div>Nom des colonnes du fichier des licenciés</div>
        <div>Nom : <input type="text" [(ngModel)]="header.surname"></div>
        <div>Prénom : <input type="text" [(ngModel)]="header.forename"></div>
        <div>Licence : <input type="text" [(ngModel)]="header.license"></div>
        <div>Nom du club : <input type="text" [(ngModel)]="header.club"></div>
    </div>
    <div class="block spaced">
        <div class="label">Année : <input type="number" [(ngModel)]="year"></div>
    </div>
    <div class="block spaced">
        <div class="label">Fichier simplifié des licenciés : </div><div class="enter"><input type="file" id="fileToImport" (change)="loadFile($event.target.files)" accept=".xlsx"></div>
    </div>
    <div *ngIf="displayReport">
        <div>Rapport d'exécution</div>
        <div>Licenciés reçus : {{report.count}}</div>
        <div>Licenciés créés : {{report.create}}</div>
        <div>Licenciés modifiés : {{report.update}}</div>
    </div>
</div>
