import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ArchiveComponent } from './pages/archive/archive.component';
import { ChallengeResultsComponent } from './pages/challenge-results/challenge-results.component';
import { ConfigurationComponent } from './pages/configuration/configuration.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { EventResultsComponent } from './pages/event-results/event-results.component';
import { EventsListComponent } from './pages/events-list/events-list.component';
import { HomeComponent } from './pages/home/home.component';
import { ImportLicensesComponent } from './pages/import-licenses/import-licenses.component';
import { ImportResultComponent } from './pages/import-result/import-result.component';
import { LoadTokenComponent } from './pages/load-token/load-token.component';
import { LoginComponent } from './pages/login/login.component';
import { RaceResultsComponent } from './pages/race-results/race-results.component';
import { RacesListComponent } from './pages/races-list/races-list.component';
import { SelectChallengeComponent } from './pages/select-challenge/select-challenge.component';
import { ExportResultComponent } from './pages/export-result/export-result.component';
import { ClubChallengeResultsComponent } from './pages/club-challenge-results/club-challenge-results.component';
import { SelectChallengeClubComponent } from './pages/select-challenge-club/select-challenge-club.component';


const routes: Routes = [
  { path: 'events/:league', component: EventsListComponent },
  { path: 'archive/:league', component: ArchiveComponent },
  { path: 'events/:league/:year', component: EventsListComponent },
  { path: 'event-results/:id', component: EventResultsComponent },
  { path: 'races-of-event/:id', component: RacesListComponent },
  { path: 'race-results/:id', component: RaceResultsComponent },
  { path: 'import-file', component: ImportResultComponent },
  { path: 'export-file', component: ExportResultComponent },
  { path: 'import-file/event/:eventId', component: ImportResultComponent },
  { path: 'import-licenses', component: ImportLicensesComponent },
  { path: 'login', component: LoginComponent },
  { path: 'load-token', component: LoadTokenComponent },
  { path: 'configuration', component: ConfigurationComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'challenge/:league/:gender/:cat/:year', component: ChallengeResultsComponent },
  { path: 'challenge/:league/:type', component: SelectChallengeComponent },
  { path: 'challenge/:league/:year/:type', component: SelectChallengeComponent },
  { path: 'challenge-club/:league/:year/:type', component: ClubChallengeResultsComponent},
  { path: 'challenge-club/:league/:year', component: SelectChallengeClubComponent},
  { path: 'challenge-club/:league', component: SelectChallengeClubComponent},
  { path: 'accueil', component: HomeComponent },
  { path: '', redirectTo: 'accueil', pathMatch: 'full' },
  { path: '**', redirectTo: 'accueil'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
