import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LeagueInfo } from 'src/app/providers/league-info';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.less']
})
export class ArchiveComponent implements OnInit {

  years: number[] = [];
  league: string;
  constructor(private dataService: DataService, private route: ActivatedRoute, private leagueInfo: LeagueInfo) { }

  ngOnInit(): void {
    this.league = this.route.snapshot.paramMap.get('league');
    this.dataService.getYears(this.leagueInfo.id).subscribe((years) => {
      years.forEach((item) => {
        this.years.push(item.year);
      });
    });
  }

}
