<div class="race-result">
    <div class="title">
        <div class="title-bloc">
            <div class="main"><a [routerLink]="['/races-of-event/' + event.id]">{{event.label}}</a>&nbsp;-&nbsp;{{event.date}}</div>
            <div class="sub"><span *ngIf="race.label != raceFormat.label">{{race.label}}&nbsp;-&nbsp;</span>Format {{raceFormat.label}}</div>
        </div>
    </div>
    <div class="table">
        <table>
            <tr>
                <th style="padding: 5px;">Cl.</th>
                <th style="padding: 5px;">Cl/cat</th>
                <th style="padding: 5px;">Nom</th>
                <th style="padding: 5px;">Sexe</th>
                <th style="padding: 5px;">Cat.</th>
                <th style="padding: 5px;">Licence</th>
                <th style="padding: 5px;">Club</th>
                <th style="padding: 5px;">Point(s) au challenge</th>
            </tr>
            <tr *ngFor="let result of results">
                <td style="padding: 5px;"><span *ngIf="result.rank != '0'">{{result.rank}}</span></td>
                <td style="padding: 5px;"><span *ngIf="result.rankCat != '0'">{{result.rankCat}}</span></td>
                <td style="padding: 5px;">{{result.surname}} {{result.forename}}</td>
                <td style="padding: 5px;">{{result.gender}}</td>
                <td style="padding: 5px;">{{result.category}}</td>
                <td style="padding: 5px;">{{result.license}}</td>
                <td style="padding: 5px;">{{result.club}}</td>
                <td style="padding: 5px; text-align: right;">{{result.point}}</td>
            </tr>
        </table>
    </div>
</div>