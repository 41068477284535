<app-waiting [waiting]="waiting" [message]="''"></app-waiting>
<app-popup [message]="message"></app-popup>
<div class="challenge-result">
    <div class="title">
        <div class="title-bloc">
            <div class="main">Challenge club {{type}} {{year}}</div>
        </div>
    </div>
    <div class="tableau">
        <div class="entete">
            <div class="c1">Cl.</div>
            <div class="c2">Pts</div>
            <div class="c3">
                Club
            </div>
        </div>
        <div class="ligne" *ngFor="let ranking of challenge; index as rank">
            <div class="c1">
                <!--{{ranking.rank}}-->
                {{rank + 1}}
            </div>
            <div class="c2">
                {{ranking.point}} <span class="pointLabel">pts</span>
            </div>
            <div class="c3">
                {{ranking.club}}
            </div>
        </div>
    </div>
</div>