import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Event } from 'src/app/model/event';
import { Message } from 'src/app/model/message';
import { LeagueInfo } from 'src/app/providers/league-info';
import { UserInfo } from 'src/app/providers/user-info';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-club-challenge-results',
  templateUrl: './club-challenge-results.component.html',
  styleUrls: ['./club-challenge-results.component.less']
})
export class ClubChallengeResultsComponent implements OnInit {

  challenge: [] = [];
  waiting: boolean;
  message: Message;
  year: number;
  type: string;

  constructor(private dataService: DataService,
              private route: ActivatedRoute,
              private userInfo: UserInfo,
              private leagueInfo: LeagueInfo) { }

  ngOnInit(): void {
    this.waiting = true;
    this.message = new Message('', '', false);

    this.type = this.route.snapshot.paramMap.get('type');
    const year = +this.route.snapshot.paramMap.get('year');
    this.year = year;
    this.dataService.getChallengeClub(this.leagueInfo.id, year, this.type).subscribe((res) => {
      this.waiting = false;
      this.challenge = res;
    }, (err) => {
      this.waiting = false;
      this.message = new Message('Erreur technique', 'Une erreur s\'est produite pendant le chargement des données. Si le problème persiste, veuillez contacter l\'administrateur');
    });
  }

}
