import { ThisReceiver } from '@angular/compiler';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Observer, race } from 'rxjs';
import { Categorie } from 'src/app/model/categorie';
import { Column } from 'src/app/model/column';
import { Event } from 'src/app/model/event';
import { Format } from 'src/app/model/format';
import { Message } from 'src/app/model/message';
import { Race } from 'src/app/model/race';
import { Sheet } from 'src/app/model/sheet';
import { Type } from 'src/app/model/type';
import { LeagueInfo } from 'src/app/providers/league-info';
import { UserInfo } from 'src/app/providers/user-info';
import { DataService } from 'src/app/services/data.service';
import { ToolsService } from 'src/app/services/tools.service';

@Component({
  selector: 'app-import-result',
  templateUrl: './import-result.component.html',
  styleUrls: ['./import-result.component.less']
})
export class ImportResultComponent implements OnInit {

  fileToImport: File = null;

  event: Event;
  raceName: string;
  raceDateTime: string;
  formats: Format[];

  fileLoaded: boolean;
  sheets: Sheet[];

  message: Message;
  waiting: boolean;
  waitingMessage: string;

  waitingLicense: string[] = [];
  year: number;
  displayFile: boolean;
  hideSaveDialogDisplay: boolean;
  savingSheet: Sheet;

  constructor(private dataService: DataService,
              private toolsService: ToolsService,
              private router: Router,
              private userInfo: UserInfo,
              private route: ActivatedRoute,
              private leagueInfo: LeagueInfo,
              private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.waiting = false;
    this.displayFile = false;
    this.hideSaveDialogDisplay = false;
    this.waitingMessage = '';
    this.message = new Message('', '', false);
    this.fileLoaded = false;
    this.event = new Event();
    this.raceName = '';
    this.raceDateTime = '';
    this.sheets = [];
    if (this.route.snapshot.paramMap.get('eventId') !== null) {
      this.waiting = true;
      const eventId = +this.route.snapshot.paramMap.get('eventId');
      this.dataService.getEvent(eventId).subscribe((event: Event) => {
        this.event = event;
        this.event.date = this.toolsService.toFrenchDate(this.toolsService.fromISODate(this.event.date));
        this.changeDate();
        this.waiting = false;
      }, (err) => {
        this.waiting = false;
      });
    }
  }

  changeDate(): void {
    try {
      if ( ! /^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/.test(this.event.date)) {
        throw new Error();
      }
      const eventDate: Date = this.toolsService.fromFrenchDate(this.event.date);
      this.year = eventDate.getFullYear();
      this.displayFile = true;
    } catch (e) {
      this.displayFile = false;
    }
  }

  init(): void {
    this.event = new Event();
    this.sheets = [];
    this.fileToImport = null;
    this.changeDate();
  }

  loadFile(fichiers: FileList) {
    // this.dataService.getPersons();
    this.waiting = true;
    this.waitingMessage = 'Chargement du fichier';
    this.sheets = [];
    if (fichiers.length > 0) {
      try {
        this.fileToImport = fichiers.item(0);
        this.dataService.getDataFromXlsx(this.fileToImport).subscribe((message) => {
          try {
            this.waitingMessage = 'Chargement des données du fichier';
            const sheet = new Sheet();
            const datasheet = JSON.parse(message);
            sheet.id = datasheet.name;
            sheet.rawResults = datasheet.data;
            sheet.columns.push({ id: 0, label: '-- select --', type: '' });
            let j = 1;
            sheet.rawResults.forEach((rawResult) => {
              for (const column in rawResult) {
                if (rawResult.hasOwnProperty(column)) {
                  if (sheet.columns.findIndex((value) => value.label === column) === -1) {
                    sheet.columns.push({ id: j++, label: column, type: '' });
                  }
                }
              }
            });
            this.fileLoaded = true;
            sheet.visibility = true;
            sheet.categories = this.dataService.getCategories();
            this.formats = [{ id: 0, label: '-- select --', multiplier: 0 }].concat(this.dataService.getFormats());
            sheet.types = this.dataService.getTypes();
            sheet.races = [ new Race(0, '', this.dataService.getCategories()) ];
            this.associateColumns(sheet.types, sheet.columns);
            sheet.rawResults.forEach((rawResult: any) => {
              // création d'une ligne résultat
              sheet.results.push({
                license: '',
                rank: 0,
                rankCat: 0,
                surname: '',
                forename: '',
                club: '',
                category: '',
                point: 0,
                check: false,
                licenseRef: '',
                seekName: false,
                nameSeeking: '',
                suggestingLicenses: []
              });
            });
            this.updateResultsDisplay(sheet, null);
            this.sheets.push(sheet);
          } catch (ex) {
            this.waiting = false;
            this.message = new Message('Erreur de chargement', 'Une erreur s\'est produite pendant le chargement du fichier xlsx.');
          }
        }, (err) => {
          this.waiting = false;
          this.message = new Message('Erreur de chargement', 'Une erreur s\'est produite pendant le chargement du fichier xlsx.');
        }, () => {});
      } catch (ex) {
        this.waiting = false;
        this.message = new Message('Erreur de chargement', 'Une erreur s\'est produite pendant le chargement du fichier xlsx.');
      }
      // console.log(fichiers);
    }
  }

  updateResultsDisplay(sheet: Sheet, type: Type): void {
    this.waiting = true;
    this.waitingMessage = 'Formatage des données';
    if (type !== null && type.id === 'race') {
      sheet.races = [];
    }
    if (type !== null && type.id === 'category') {
      if (!sheet.multipleRace) {
        sheet.races[0].categories = [];
        sheet.races[0].inaffectedCategories = [];
      }
    }
    // sheet.results = [];
    // boucle sur les résultats bruts
    sheet.rawResults.forEach((rawResult: any, i: number) => {
      // création d'une ligne résultat
      const result = sheet.results[i];
      let currentRace: Race;
      let currentCategory: string = null;
      let currentGender: string = null;
      // boucle sur tous les types de données recherchées
      sheet.types.forEach((currentType: Type) => {
        // si on a sélectionné la colonne correspondante, on récupère la donnée
        if (currentType.columnId > 0) {
          if (currentType.id !== 'category') {
            if (typeof rawResult[sheet.columns[currentType.columnId].label] !== 'undefined') {
              result[currentType.id] = rawResult[sheet.columns[currentType.columnId].label].trim();
            }
          }
          // si on est sur la colonne du nom de la course
          if (type !== null && type.id === 'race') {
            if (currentType.id === type.id) {
              // recherche si la course est déjà enregistrée
              let raceExist = false;
              sheet.races.forEach((race: Race) => {
                if (race.label === result[currentType.id]) {
                  raceExist = true;
                  currentRace = race;
                }
              });
              // non trouvée, ,elle est créée
              if (!raceExist) {
                const newRace: Race = new Race(sheet.races.length, result[currentType.id], this.dataService.getCategories());
                sheet.races.push(newRace);
                currentRace = newRace;
              }
            }
          } else {
            if (currentType.id === 'race') {
              sheet.races.forEach((race: Race) => {
                if (race.label === result[currentType.id]) {
                  currentRace = race;
                }
              });
            }
          }
          // enregistrement de la catégorie si connue
          if (currentType.id === 'category') {
            currentCategory = rawResult[sheet.columns[currentType.columnId].label];
          }
          // enregistrement du sexe si connu
          if (currentType.id === 'gender') {
            currentGender = result[currentType.id];
          }
        } else {
          if (type !== null && currentType.id === type.id) {
            result[currentType.id] = '';
          }
          if (currentType.id === 'gender') {
            if (!sheet.races[0].uniqueGender) {
              result[currentType.id] = '';
            }
          }
        }
      });
      if (sheet.multipleRace) {
        if (typeof currentRace !== 'undefined') {
          if (currentRace.uniqueGender && currentRace.gender !== '') {
            result.gender = currentRace.gender;
          }
          if (currentCategory !== null) {
            if (currentRace.categories.findIndex((value: string) => value === currentCategory) < 0) {
              currentRace.categories.push(currentCategory);
            }
          }
          if (currentGender !== null) {
            if (currentRace.genders.findIndex((value: any) => value.code === currentGender && value.code !== '' ) < 0) {
              currentRace.genders.push({ code: currentGender, label: currentGender});
            }
          }
        }
      } else {
        if (sheet.races[0].uniqueGender && sheet.races[0].gender !== '') {
          result.gender = sheet.races[0].gender;
        }
        if (currentCategory !== null) {
          if (sheet.races[0].categories.findIndex((value: string) => value === currentCategory) < 0) {
            sheet.races[0].categories.push(currentCategory);
          }
        }
        if (currentGender !== null) {
          if (sheet.races[0].genders.findIndex((value: any) => value.code === currentGender && value.code !== '' ) < 0) {
            sheet.races[0].genders.push({ code: currentGender, label: currentGender});
          }
        }
      }
    });
    this.waitingMessage = 'Association des sexes et catégories';
    this.associateCategories(sheet);
    this.associateGender(sheet);
    sheet.races.forEach((race: Race) => {
      this.translateCategory(sheet, sheet.rawResults, sheet.results, race);
    });
    /*
    sheet.iteration = 0;
    let c = 0;
    this.waitingMessage = 'Recherche des licences';
    while (sheet.iteration < sheet.results.length && c < 10) {
      this.getLicense(sheet);
      sheet.iteration++;
      c++;
    }
    */
    this.getLicenses(sheet);
    // this.waiting = false;
  }

  changeGender(sheet: Sheet): void {
    this.updateResultsDisplay(sheet, null);
  }

  updateMultiRace(sheet: Sheet): void {
    if (sheet.multipleRace) {
      sheet.types.forEach((type: Type) => {
        if (type.id === 'race') {
          type.columnId = 0;
        }
      });
      sheet.races = [];
    } else {
      sheet.races = [ new Race(0, '', this.dataService.getCategories()) ];
    }
    this.updateResultsDisplay(sheet, null);
  }
  associateCategories(sheet: Sheet): void {
    // boucle sur les catégories pour associer les catégories type avec les libellés de la feuille de résultat
    if (sheet.multipleRace) {
      sheet.races.forEach((race: Race) => {
        this.associateCategory(race.categoriesForPoint, race.categories, race.inaffectedCategories);
      });
    } else {
      this.associateCategory(sheet.races[0].categoriesForPoint, sheet.races[0].categories, sheet.races[0].inaffectedCategories);
    }
  }
  associateCategory(categoriesTypes: Categorie[], categoriesLabels: string[], inaffectedCategories: any[]) {
    categoriesLabels.forEach((categoryLabel: string) => {
      let labelAffected = false;
      categoriesTypes.forEach((category: Categorie, index: number, categories: Categorie[]) => {
        if (((categoryLabel.toLowerCase().startsWith('mp')
          || (categoryLabel.toLowerCase().startsWith('mini')) && !categoryLabel.toLowerCase().startsWith('minime')) && category.id === 'MP')
        || (categoryLabel.toLowerCase().startsWith('po') && category.id === 'PO')
        || (categoryLabel.toLowerCase().startsWith('pu') && category.id === 'PU')
        || (categoryLabel.toLowerCase().startsWith('b') && category.id === 'BE')
        || (categoryLabel.toLowerCase().startsWith('mi') && !categoryLabel.toLowerCase().startsWith('mini-') && category.id === 'MI')
        || (categoryLabel.toLowerCase().startsWith('c') && category.id === 'CA')
        || (categoryLabel.toLowerCase().startsWith('j') && category.id === 'JU')
        || (categoryLabel.toLowerCase().startsWith('s') && category.id === 'SE')
        || ((categoryLabel.toLowerCase().startsWith('v') || categoryLabel.toLowerCase().startsWith('ma')) && category.id === 'VE')) {
          if (categories[index].labelsInResult.indexOf(categoryLabel) < 0) {
            categories[index].labelsInResult.push(categoryLabel);
          }
          labelAffected = true;
        }
      });
      if (!labelAffected) {
        if (inaffectedCategories.findIndex((value) => value.code === categoryLabel) < 0) {
          inaffectedCategories.push({ code: categoryLabel, label: categoryLabel });
        }
      }
    });
  }
  deleteAssociationCategory(label: string, categorie: Categorie,  race: Race, sheet: Sheet): void {
    categorie.labelsInResult = categorie.labelsInResult.filter((value) => value !== label );
    race.inaffectedCategories.push({ code: label, label: label });
    this.translateCategory(sheet, sheet.rawResults, sheet.results, race);
  }

  translateCategory(sheet: Sheet, rawResults, results, race: Race): void {
    let columnId = 0;
    sheet.types.forEach((type: Type) => {
      if (type.id === 'category') {
        columnId = type.columnId;
      }
    });
    if (columnId > 0) {
      rawResults.forEach((rawResult, i) => {
        if (results[i].race === race.label || !sheet.multipleRace) {
          results[i].category = '';
          race.categoriesForPoint.forEach((category) => {
            category.labelsInResult.forEach((label) => {
              if (label === rawResult[sheet.columns[columnId].label]) {
                results[i].category = category.id;
              }
            });
          });
        }
      });
    }
  }

  associateGender(sheet: Sheet): void {
    sheet.races.forEach((race: Race, i: number, races: Race[]) => {
      if (!race.uniqueGender) {
        race.genders.forEach((gender: any) => {
          if (gender.code.toLowerCase().startsWith('m')) {
            races[i].maleLabel = gender.code;
          }
          if (gender.code.toLowerCase().startsWith('f')) {
            races[i].femaleLabel = gender.code;
          }
        });
      }
    });
  }

  associateColumns(types: Type[], columns: Column[]) {
    types.forEach((type: Type) => {
      columns.forEach((column: Column) => {
        if (type.id === 'surname' && column.label.toLowerCase() === 'nom') {
          type.columnId = column.id;
        }
        if (type.id === 'forename' && (column.label.toLowerCase() === 'prenom' || column.label.toLowerCase() === 'prénom')) {
          type.columnId = column.id;
        }
        if (type.id === 'license' && column.label.toLowerCase() === 'licence') {
          type.columnId = column.id;
        }
        if (type.id === 'rank' &&
        (column.label.toLowerCase() === 'classement' || column.label.toLowerCase() === 'rank'
        || column.label.toLowerCase() === 'cl.' || column.label.toLowerCase() === 'pl.' || column.label.toLowerCase() === 'place'
        || column.label.toLowerCase() === 'cl' || column.label.toLowerCase() === 'pl')) {
          type.columnId = column.id;
        }
        if (type.id === 'category' &&
        (column.label.toLowerCase() === 'catégorie' || column.label.toLowerCase() === 'cat.'
        || column.label.toLowerCase() === 'categorie' || column.label.toLowerCase() === 'cat')) {
          type.columnId = column.id;
        }
        if (type.id === 'gender' &&
        (column.label.toLowerCase().startsWith('sex'))) {
          type.columnId = column.id;
        }
        if (type.id === 'club' &&
        (column.label.toLowerCase().startsWith('club'))) {
          type.columnId = column.id;
        }
        /*
        if (type.id === 'race' &&
        (column.label.toLowerCase() === 'course' || column.label.toLowerCase() === 'epreuve'
        || column.label.toLowerCase() === 'épreuve')) {
          type.columnId = column.id;
        }
        */
      });
    });
  }

  addCategory(race: Race, category: Categorie, sheet: Sheet): void {
    if (category.inaffectedCategory !== '') {
      category.labelsInResult.push(category.inaffectedCategory);
      race.inaffectedCategories.splice(race.inaffectedCategories.findIndex(
        (cat: any) => cat.code === category.inaffectedCategory),
        1);
      category.inaffectedCategory = '';
    }
    this.translateCategory(sheet, sheet.rawResults, sheet.results, race);
  }

  calculate(sheet: Sheet): void {
    sheet.races.forEach((race: Race, i: number, races: Race[]) => {
      races[i].categoriesForPoint.forEach((categorie: Categorie, j: number, categories: Categorie[]) => {
        categories[j].currentFemaleRank = 0;
        categories[j].currentMaleRank = 0;
      });
    });

    sheet.results.forEach((result: any, i: number, results: any[]) => {
      const pointsForYoung = this.dataService.getPointsByRankForYoung();
      const pointsForAdult = this.dataService.getPointsByRankForAdult();
      // recherche de l'épreuve
      let currentRace: Race = null;
      let raceFormat: Format = null;
      if (sheet.multipleRace) {
        currentRace = sheet.races.find((race: Race) => race.label === result.race);
      } else {
        currentRace = sheet.races[0];
      }
      if (currentRace !== null) {
        // recherche du format
        raceFormat = this.dataService.getFormats()[currentRace.format - 1];
        // recherche de la catégorie
        let currentCategory: Categorie = null;
        if (currentRace.uniqueCategory) {
          currentCategory = currentRace.categoriesForPoint.find((category: Categorie) => category.id === currentRace.category );
        } else {
          currentRace.categoriesForPoint.forEach((category: Categorie) => {
            if (category.id === result.category) {
              currentCategory = category;
            }
          });
        }
        // recherche du sexe et du classement
        let currentRank = 0;
        if (typeof currentCategory !== 'undefined' && currentCategory !== null && result.licenseFound) {
          if (currentRace.uniqueGender) {
            if (currentRace.gender === 'M') {
              currentRank = ++currentCategory.currentMaleRank;
            }
            if (currentRace.gender === 'F') {
              currentRank = ++currentCategory.currentFemaleRank;
            }
          } else {
            if (currentRace.maleLabel === result.gender) {
              currentRank = ++currentCategory.currentMaleRank;
            }
            if (currentRace.femaleLabel === result.gender) {
              currentRank = ++currentCategory.currentFemaleRank;
            }
          }
        }

        if (currentRank > 0) {
          if (currentCategory.selected || currentRace.uniqueCategory) {
            result.rankCat = currentRank;
            if (this.leagueInfo.id === result.leagueRef) {
              if (currentCategory.young && currentRank <= pointsForYoung.length) {
                result.point = pointsForYoung[currentRank - 1].point;
              } else {
                result.point = 1;
              }
              if (!currentCategory.young && currentRank <= pointsForAdult.length) {
                result.point = pointsForAdult[currentRank - 1].point * raceFormat.multiplier;
              }
            }
          } else {
            result.point = 0;
          }
        } else {
          result.point = 0;
        }
      }
    });
    sheet.calculated = true;
  }

  getLicenses(sheet: Sheet): void {
    this.waitingLicense.push('waiting');
    let licencesIndex: any = {};
    sheet.results.forEach((result: any, index: number, results: any[]) => {
      results[index].error = true;
      results[index].licenseFound = false;
      if (typeof result.license !== "undefined" && result.license !== "") {
        licencesIndex[result.license.substring(0, 6)] = index;
      }
    })
    const licenses: string[] = sheet.results.filter(result => result.license !== "").map(result => result.license.substring(0, 6));
    this.dataService.getLicensesList(licenses, this.year).subscribe({
      next: (licenses: any[]) => {
        licenses.forEach((license) => {
          const i: number = licencesIndex[license.id];
          if (typeof i !== "undefined" && sheet.results[i].license.substring(0, 6) == license.id) {
            if (license.surname.trim().toLowerCase() === sheet.results[i].surname.trim().toLowerCase()
            && license.forename.trim().toLowerCase() === sheet.results[i].forename.trim().toLowerCase()
            && license.category.trim().toLowerCase() === sheet.results[i].category.trim().toLowerCase()) {
              sheet.results[i].check = true;
              sheet.results[i].error = false;
            } else {
              sheet.results[i].attention = true;
            }
            sheet.results[i].licenseRef = license.id;
            sheet.results[i].nameRef = `${license.surname} ${license.forename}`;
            sheet.results[i].surnameRef = license.surname;
            sheet.results[i].forenameRef = license.forename;
            sheet.results[i].genderRef = license.gender;
            sheet.results[i].categoryRef = license.category;
            sheet.results[i].clubRef = license.club;
            sheet.results[i].leagueRef = license.league;
            sheet.results[i].licenseFound = true;
          }
        });
      },
      error: (error) => {
      },
      complete: () => {
        this.waitingLicense.pop();
        if (this.waitingLicense.length === 0) {
          this.waiting = false;
        }
      }
    });

    let namesindex: any = {};
    const names: any[] = sheet.results
          .filter(result => typeof result.license === "undefined" || result.license === "")
          .map(result => { return { surname: result.surname, forename: result.forename, category: result.category } } );
    sheet.results.forEach((result: any, index: number) => {
      if (typeof result.license === "undefined" || result.license === "") {
        namesindex[result.surname + "_" + result.forename] = index;
      }
    })
    for (let z = 0; z * 50 < names.length; z++) {
      let subNames = names.slice(z * 50 , ((z + 1) * 50) -1);
      this.dataService.getMultiSearchLicenseByName(subNames, this.year).subscribe({
        next: (results: any[]) => {
          names.forEach((name: any) => {
            const result = results.filter((result) => result.criteria.filter_equal_surname == name.surname && result.criteria.filter_equal_forename == name.forename && result.criteria.filter_equal_category == name.category);
            if (result.length === 1) {
              if (result[0].count === 1) {
                const i: number = namesindex[name.surname + "_" + name.forename];
                sheet.results[i].check = true;
                sheet.results[i].error = false;
                sheet.results[i].licenseRef = result[0].licenses[0].id;
                sheet.results[i].surnameRef = result[0].licenses[0].surname;
                sheet.results[i].forenameRef = result[0].licenses[0].forename;
                sheet.results[i].nameRef = `${result[0].licenses[0].surname} ${result[0].licenses[0].forename}`;
                sheet.results[i].genderRef = result[0].licenses[0].gender;
                sheet.results[i].categoryRef = result[0].licenses[0].category;
                sheet.results[i].clubRef = result[0].licenses[0].club;
                sheet.results[i].leagueRef = result[0].licenses[0].league;
                sheet.results[i].licenseFound = true;
              } else {
                const i: number = namesindex[name.surname + "_" + name.forename];
                sheet.results[i].licenseFound = false;
              }
            }
          });
        },
        error: (error) => {

        },
        complete: () => {
          this.waitingLicense.pop();
          if (this.waitingLicense.length === 0) {
            this.waiting = false;
          }
        }
      });
    }
  }

  getLicense(sheet: Sheet): void {
    const i = sheet.iteration;
    if (sheet.results[i].license !== '' && typeof sheet.results[i].license !== 'undefined') {
      if  (sheet.results[i].license.substring(0, 6) !== sheet.results[i].licenseRef
      && sheet.results[i].license.length > 5) {
        this.dataService.getLicense(sheet.results[sheet.iteration].license.substring(0, 6), this.year).subscribe({
          next: (license) => {
            if (license.surname.trim().toLowerCase() === sheet.results[i].surname.trim().toLowerCase()
            && license.forename.trim().toLowerCase() === sheet.results[i].forename.trim().toLowerCase()
            && license.category.trim().toLowerCase() === sheet.results[i].category.trim().toLowerCase()) {
              sheet.results[i].check = true;
            } else {
              sheet.results[i].attention = true;
            }
            sheet.results[i].licenseRef = license.id;
            sheet.results[i].nameRef = `${license.surname} ${license.forename}`;
            sheet.results[i].surnameRef = license.surname;
            sheet.results[i].forenameRef = license.forename;
            sheet.results[i].genderRef = license.gender;
            sheet.results[i].categoryRef = license.category;
            sheet.results[i].clubRef = license.club;
            sheet.results[i].leagueRef = license.league;
            sheet.results[i].licenseFound = true;
            this.nextGetLicenseRequest(sheet);
          },
          error: (error) => {
            sheet.results[i].licenseFound = false;
            if (error.status === 404) {
              sheet.results[i].error = true;
            }
            this.nextGetLicenseRequest(sheet);
          },
          complete: () => {
          }
        });
      } else {
        this.nextGetLicenseRequest(sheet);
      }
    } else if (sheet.results[i].surname !== '' && sheet.results[i].forename !== ''
    && sheet.results[i].nameRef !== `${sheet.results[i].surnameRef} ${sheet.results[i].forenameRef}`) {
      this.dataService.getLicenseByName(sheet.results[i].surname,
        sheet.results[sheet.iteration].forename, this.year).subscribe({
          next: (licenses) => {
            if (Array.isArray(licenses) && licenses.length === 1) {
              sheet.results[i].check = true;
              sheet.results[i].licenseRef = licenses[0].id;
              sheet.results[i].surnameRef = licenses[0].surname;
              sheet.results[i].forenameRef = licenses[0].forename;
              sheet.results[i].nameRef = `${licenses[0].surname} ${licenses[0].forename}`;
              sheet.results[i].genderRef = licenses[0].gender;
              sheet.results[i].categoryRef = licenses[0].category;
              sheet.results[i].clubRef = licenses[0].club;
              sheet.results[i].leagueRef = licenses[0].league;
              sheet.results[i].licenseFound = true;
            } else {
              sheet.results[i].licenseFound = false;
              sheet.results[i].error = true;
            }
            this.nextGetLicenseRequest(sheet);
          },
          error: () => {
            sheet.results[i].licenseFound = false;
            this.nextGetLicenseRequest(sheet);
          },
          complete: () => {
          }
        });
    } else {
      sheet.results[i].licenseFound = false;
      this.nextGetLicenseRequest(sheet);
    }
  }

  nextGetLicenseRequest(sheet: Sheet): void {
    this.waitingLicense.pop();
    sheet.iteration++;
    // console.log(`license search for ${sheet.id} : ${sheet.iteration}/${sheet.results.length}`);
    // console.log(`license searching for ${sheet.id} : ${this.waitingLicense.length}`);
    if (sheet.iteration < sheet.results.length) {
      this.getLicense(sheet);
    } else {
      sheet.licensesFound = 0;
      sheet.results.forEach((result) => {
        if (result.licenseFound === true) {
          sheet.licensesFound++;
        }
      });
      if (this.waitingLicense.length === 0) {
        this.waiting = false;
      }
    }
  }

  save(sheet: Sheet): void {
    try {
      if (this.controlData(this.event, sheet)) {
        this.savingSheet = sheet;
        this.hideSaveDialogDisplay = true;
        const dateEvent = this.toolsService.fromFrenchDate(this.event.date);
        if (this.event.id > 0) {
          this.event.saved = true;
          this.saveRace(sheet, this.event);
        } else {
          this.dataService.postEvent({
            id: 0,
            label: this.event.label,
            date: this.toolsService.toISODateOnly(dateEvent),
            league: this.leagueInfo.id,
            saved: false } )
            .subscribe((createdEvent: Event) => {
              this.event.saved = true;
              this.event.id = createdEvent.id;
              this.saveRace(sheet, createdEvent);
            }, (err) => {
              this.waiting = false;
            });
        }
      } else {
      }
    } catch (ex) {
      this.message = new Message('Enregistrement', ex.message, true);
    }
  }

  saveRace(sheet: Sheet, event: Event): void {
    // for (let i = 0; i < sheet.races.length; i++) {
    //   const race = sheet.races[i];
    sheet.races.forEach((race: Race) => {
      const raceToCreate: any = {};
      raceToCreate.label = race.label;
      raceToCreate.format = race.format;
      raceToCreate.categories = race.categoriesForPoint.filter((cat: Categorie) => cat.selected).map<string>((cat: Categorie) => cat.id);
      raceToCreate.gender = race.gender;
      raceToCreate.eventId = event.id;

      const results = [];
      sheet.results.forEach((res) => {
        if (!sheet.multipleRace || (sheet.multipleRace && race.label === res.race)) {
          const result: any = {};
          if (typeof res.licenseRef !== 'undefined' && res.licenseRef !== '') {
            result.license = res.licenseRef;
            result.surname = res.surnameRef;
            result.forename = res.forenameRef;
            result.gender = res.genderRef.toUpperCase();
            result.club = res.clubRef;
            result.category = res.categoryRef;
            result.league = res.leagueRef;
          } else {
            result.license = res.license.substring(0, 6);
            result.surname = res.surname;
            result.forename = res.forename;
            result.gender = res.gender.toUpperCase();
            result.club = res.club;
            result.category = res.category;
            result.league = '';
          }
          result.rank = res.rank;
          result.rankCat = res.rankCat;
          result.point = res.point;
          results.push(result);
        }
      });

      this.dataService.postRace(raceToCreate, results).subscribe((createdRace: Race) => {
        race.saved = true;
        race.resultsSaved = true;
        sheet.saved = true;
        /*
        this.dataService.postResults(results, createdRace).subscribe((res) => {
          // console.log(res);
          race.resultsSaved = true;
          sheet.saved = true;
          this.cdr.detectChanges();
        }, (err) => {

        });
        */
        // console.log(createdRace);
      }, (err) => {
      });
    // }
    });
  }

  searchLicense(sheet, result, i: number): void {
    result.seekName = true;
    if (sheet.results[i].licenseRef !== '') {
      sheet.licensesFound--;
    }
    sheet.results[i].check = false;
    sheet.results[i].attention = false;
    sheet.results[i].error = false;
    sheet.results[i].licenseRef = '';
    sheet.results[i].nameRef = '';
    sheet.results[i].clubRef = '';
    new Observable((observer: Observer<any>) => {
      setTimeout(() => {
        observer.next(null);
        observer.complete();
      }, 500);
    }).subscribe((res) => {
      document.getElementById('nameSeeking' + i).focus();
    });
  }

  readLicenses(result): void {
    if (result.nameSeeking !== '') {
      this.dataService.getLicenseBySurname(result.nameSeeking, this.year).subscribe((res) => {
        result.suggestingLicenses = res;
        result.licensesFound = true;
      });
    } else {
      result.suggestingLicenses = [];
      result.licensesFound = false;
    }
  }

  selectLicense(result, suggestingLicense, sheet): void {
    result.nameSeeking = '';
    result.check = true;
    result.licenseRef = suggestingLicense.id;
    result.nameRef = `${suggestingLicense.surname} ${suggestingLicense.forename}`;
    result.clubRef = suggestingLicense.club;
    result.licensesFound = false;
    result.seekName = false;
    result.categoryRef = suggestingLicense.category;
    result.check = true;
    sheet.licensesFound++;
  }

  blur(result): void {
    new Observable((observer: Observer<any>) => {
      setTimeout(() => {
        observer.next(null);
        observer.complete();
      }, 500);
    }).subscribe((res) => {
      result.seekName = false;
      result.licensesFound = false;
      // result.error = true;
    });
  }

  controlData(event: Event, sheet: Sheet): boolean {
    if (event.label === '') {
      throw new Error('Veuillez renseigner le nom de l\'évènement.');
    }
    try {
      this.toolsService.fromFrenchDate(this.event.date);
    } catch (ex) {
      throw new Error('La date de l\'évènement doit être au format jj/mm/aaaa.');
    }
    if (sheet.multipleRace) {
      if (sheet.types.filter((type) => type.id === 'race' && type.columnId > 0).length === 0) {
        throw new Error('La colonne contenant le nom de l\'épreuve n\'est pas sélectionnée.');
      }
    } else {
      const race: Race = sheet.races[0];
      if (race.label === '') {
        throw new Error('L\'intitulé de l\'épreuve n\'est pas renseigné');
      }
      this.controlRace(race);
    }
    return this.controlResult(sheet.results);
  }

  controlRace(race: Race): void {
    if (race.format === 0) {
      throw new Error('Le format n\'est pas renseigné');
    }
    if (race.uniqueGender) {
      if (race.gender === '') {
        throw new Error('Le sexe de l\'épreuve n\'est pas renseigné');
      }
    } else {
      if (race.maleLabel === '' || race.femaleLabel === '') {
        throw new Error('La correspondance des sexes de l\'épreuve n\'est pas renseignée');
      }
    }
    if (race.uniqueCategory) {
      if (race.category === '') {
        throw new Error('La catégorie de la course marquant des points au challenge n\'est pas sélectionnée.');
      }
    }
  }

  controlResult(results: any[]): boolean {
    let resultValid = true;
    results.forEach((result) => {
      if (result.error || result.attention) {
        resultValid = false;
      }
    });
    if (!resultValid) {
      return confirm('La recherche de licence relève certains incohérences, Etes-vous sûr de vouloir enregistrer les résultats ?');
    }
    return true;
  }

  hideSaveDialog(): void {
    this.hideSaveDialogDisplay = false;
  }
}
