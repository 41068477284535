import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Event } from 'src/app/model/event';
import { UserInfo } from 'src/app/providers/user-info';
import { DataService } from 'src/app/services/data.service';
import { Message } from 'src/app/model/message';
import { LeagueInfo } from 'src/app/providers/league-info';

@Component({
  selector: 'app-challenge-results',
  templateUrl: './challenge-results.component.html',
  styleUrls: ['./challenge-results.component.less']
})
export class ChallengeResultsComponent implements OnInit {

  challenge: any = {};
  event: Event;
  currentEventIndex: number;
  waiting: boolean;
  message: Message;
  year: number;
  catLabel: string;
  genderLabel: string;

  displayLicense: boolean;
  licenseIdToDisplay: string;
  yearOfLicenseHistory: string;

  constructor(private dataService: DataService,
              private route: ActivatedRoute,
              private userInfo: UserInfo,
              private leagueInfo: LeagueInfo) { }

  ngOnInit(): void {
    this.waiting = true;
    this.message = new Message('', '', false);

    this.displayLicense = false;

    const cat = this.route.snapshot.paramMap.get('cat');
    const year = +this.route.snapshot.paramMap.get('year');
    const gender = this.route.snapshot.paramMap.get('gender');
    if (gender === 'M') {
      this.genderLabel = 'Masculin';
    } else {
      this.genderLabel = 'Féminin';
    }
    this.year = year;
    this.event = new Event();
    this.catLabel = this.dataService.getCategories().find(item => item.id === cat).label;
    this.dataService.getChallenge(this.leagueInfo.id, year, cat, gender).subscribe((res) => {
      this.waiting = false;
      if (res.events && res.events.length > 0) {
        this.challenge = res;
        this.event = this.challenge.events[0];
        this.currentEventIndex = 0;
      } else {
        this.event = new Event();
        this.currentEventIndex = -1;
      }
    }, (err) => {
      this.waiting = false;
      this.message = new Message('Erreur technique', 'Une erreur s\'est produite pendant le chargement des données. Si le problème persiste, veuillez contacter l\'administrateur');
    });
  }
  prevEvent(): void {
    if (this.currentEventIndex > 0) {
      this.event = this.challenge.events[--this.currentEventIndex];
    }
  }
  nextEvent(): void {
    if (this.currentEventIndex < this.challenge.events.length - 1) {
      this.event = this.challenge.events[++this.currentEventIndex];
    }
  }

  displayLicenseHistory(licenseId, year): void {
    this.licenseIdToDisplay = licenseId;
    this.yearOfLicenseHistory = year;
    this.displayLicense = true;
  }

  changeLicenseId(licenseId): void {
    this.licenseIdToDisplay = licenseId;
  }

}
