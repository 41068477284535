export class Message {
    title: string;
    content: string;
    display: boolean;

    constructor(title: string, content: string, display?: boolean) {
        this.title = title;
        this.content = content;
        this.display = display == null ? true : display;
    }
}
