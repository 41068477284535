import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Event } from 'src/app/model/event';
import { Race } from 'src/app/model/race';
import { UserInfo } from 'src/app/providers/user-info';
import { DataService } from 'src/app/services/data.service';
import { ToolsService } from 'src/app/services/tools.service';

@Component({
  selector: 'app-races-list',
  templateUrl: './races-list.component.html',
  styleUrls: ['./races-list.component.less']
})
export class RacesListComponent implements OnInit {

  races: Race[];
  event: Event;
  connectedUserInfo: UserInfo;
  constructor(private dataService: DataService,
              private route: ActivatedRoute,
              private userInfo: UserInfo,
              private tool: ToolsService) { }

  ngOnInit() {
    this.races = [];
    this.event = new Event();
    const eventId = +this.route.snapshot.paramMap.get('id');
    const formats = this.dataService.getFormats();
    const categories = this.dataService.getCategories();
    this.dataService.getEvent(eventId).subscribe((event: Event) => {
      event.date = this.tool.toFrenchDate(this.tool.fromISODate(event.date));
      this.event = event;
    });
    this.dataService.getRaces(eventId).subscribe((races) => {
      races.forEach(element => {
        element.labelFormat = formats[element.format - 1].label;
        const cats = [];
        element.categories.split(';').forEach(cat => {
          try {
          cats.push(categories.filter((categorie) => cat === categorie.id)[0].label);
          } catch (e) {}
        });
        element.cats = cats.join(', ');
        this.races.push(element);
      });
      this.races = races;
    });
    this.connectedUserInfo = this.userInfo;
  }

  deleteRace(race: Race): void {
    if (confirm('Etes-vous sûr de vouloir supprimer cette épreuve ?')) {
      this.dataService.deleteRace(race.id).subscribe((res) => {
        const index = this.races.findIndex((item) => item.id === race.id);
        this.races.splice(index, 1);
      });
    }
  }
}
