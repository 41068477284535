import { Component, OnInit } from '@angular/core';
import { Data, Router } from '@angular/router';
import { UserInfo } from 'src/app/providers/user-info';
import { UserService } from 'src/app/services/user.service';
import { Md5} from 'ts-md5/dist/md5';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {

  user: string;
  password: string;
  message: string;
  constructor(private userService: UserService,
              private userInfo: UserInfo,
              private router: Router) { }

  ngOnInit() {
    this.user = '';
    this.password = '';
    this.message = '';
  }

  authenticate(): void {
    this.message = '';
    if (this.user === '' || this.password === '') {
      this.message = 'Veuillez renseigner Login et Mot de passe.';
      return;
    }
    const pass = Md5.hashStr(this.password);
    let sPass = '';
    if (typeof pass === 'string') {
      sPass = pass;
    }
    this.userService.authenticate(this.user, sPass).subscribe((res) => {
      localStorage.setItem('token', res.token);
      this.userInfo.user.surname = res.surname;
      this.userInfo.user.forename = res.forename;
      this.userInfo.authentified = true;
      this.router.navigateByUrl('/dashboard');
    }, (err) => {
      if (err.status === 404) {
        this.message = 'Login ou Mot de passe incorrect.';
      } else {
        this.message = 'Erreur technique.';
      }
    });
  }

}
