<div class="back-dialog couche-100" *ngIf="message.display">
    <div class="dialog dialog-popup">
        <div class="window">
            <div class="border">
                <div class="title">
                    {{message.title}}
                </div>
                <div class="content">
                    {{message.content}}
                </div>
                <div class="button">
                    <input type="button" value="OK" (click)="hide()">
                    <input type="button" value="Annuler" (click)="cancel()" *ngIf="type ==='confirm'">
                </div>
            </div>
        </div>
    </div>
</div>