import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Message } from 'src/app/model/message';
import { LeagueInfo } from 'src/app/providers/league-info';
import { UserInfo } from 'src/app/providers/user-info';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-import-licenses',
  templateUrl: './import-licenses.component.html',
  styleUrls: ['./import-licenses.component.less']
})
export class ImportLicensesComponent implements OnInit {

  fileToImport: File;
  header: any = {
    surname: 'Nom',
    forename: 'Prénom',
    license: 'Numéro de licence',
    club: 'Club'
  };
  report: any = {};
  displayReport = false;
  waiting: boolean;
  message: Message;
  year: number;

  constructor(private dataService: DataService,
              private router: Router,
              private userInfo: UserInfo,
              private leagueInfo: LeagueInfo) { }

  ngOnInit() {
    this.message = new Message('', '', false);
    this.waiting = false;
    this.year = new Date().getFullYear();
  }

  loadFile(fichiers: FileList) {
    // this.dataService.getPersons();
    const licenseNumberRegex = /([A-Z]{1}\d{5})[A-Z]{1}\d{7}([FM]{1})(.{2})[A-Z]{3}/;
    let formattedLicense: RegExpExecArray;
    this.waiting = true;
    try {
      if (fichiers.length > 0) {
        this.fileToImport = fichiers.item(0);
        this.dataService.getDataFromXlsx(this.fileToImport).subscribe((message) => {
          const licensesToUpload = [];
          const datasheet = JSON.parse(message);
          const sheetName = datasheet.name;
          const licenses = datasheet.data;
          if (Array.isArray(licenses)) {
            licenses.forEach((license) => {
              if (!licenseNumberRegex.test(license[this.header.license])) {
                this.waiting = false;
                alert(`Le numéro de license n\'est pas au format attendu : ${license[this.header.license]}`);
                throw new Error();
              }
              formattedLicense = licenseNumberRegex.exec(license[this.header.license]);
              const licenseToUpload: any = {};
              licenseToUpload.id = formattedLicense[1];
              licenseToUpload.licenseId = license[this.header.license];
              licenseToUpload.surname = license[this.header.surname].trim();
              licenseToUpload.forename = license[this.header.forename].trim();
              // console.log(formattedLicense);
              licenseToUpload.gender = formattedLicense[2];
              licenseToUpload.category = formattedLicense[3];
              if (licenseToUpload.category.substring(0, 1) === 'S') {
                licenseToUpload.category = 'SE';
              }
              if (licenseToUpload.category.substring(0, 1) === 'V') {
                licenseToUpload.category = 'VE';
              }
              licenseToUpload.club = license[this.header.club] ? license[this.header.club].trim() : '';
              licenseToUpload.year = this.year;
              licenseToUpload.league = this.leagueInfo.id;
              // console.log(licenseToUpload);
              licensesToUpload.push(licenseToUpload);
            });
          }

          this.dataService.postLicenses(new Date().getFullYear(), this.leagueInfo.id, licensesToUpload).subscribe((res) => {
            this.report = res;
            this.displayReport = true;
            this.waiting = false;
          }, (err) => {
            this.waiting = false;
            this.message = new Message('Erreur technique', 'Une erreur s\'est produite pendant le chargement des données. Si le problème persiste, veuillez contacter l\'administrateur');
          });

        });
      } else {
        this.waiting = false;
      }
    } catch (ex) {
      this.waiting = false;
      this.message = new Message('Erreur technique', 'Une erreur s\'est produite pendant le chargement des données. Si le problème persiste, veuillez contacter l\'administrateur');
    }
  }
}
